import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
  const [playerCountNA, setPlayerCountNA] = useState(null);
  const [playerCountAS, setPlayerCountAS] = useState(null);

  useEffect(() => {
    const fetchPlayerCounts = async () => {
      try {
        const response = await fetch('https://starve.io/list');
        const data = await response.json();

        const naServer = data.find(server => server.n === 'atlanta');
        const naPlayerCount = naServer?.l.find(
          server => server[0] === 'community204'
        )?.[3];

        // const asServer = data.find(server => server.n === 'osaka');
        // const asPlayerCount = asServer?.l.find(
        //   server => server[0] === 'community224'
        // )?.[3];
        const asPlayerCount = 0;

        setPlayerCountNA(naPlayerCount);
        setPlayerCountAS(asPlayerCount);
      } catch {}
    };

    fetchPlayerCounts();
  }, []);

  return (
    <div className="container mx-auto py-8 px-4 text-center relative">
      {/* Player Count Graphic */}
      <div className="absolute top-4 left-4 bg-darker p-4 rounded shadow-lg text-left">
        <h3 className="text-lg font-bold text-accentCyan mb-2">Players Online</h3>
        <p className="text-sm text-lightGray mb-1">
          NA (Atlanta): {playerCountNA !== null ? playerCountNA : 'Loading...'}
        </p>
        <p className="text-sm text-lightGray mb-1">
          Asia (Osaka): {playerCountAS !== null && playerCountAS !== NaN && playerCountAS !== 0? playerCountAS : "0"}
        </p>
        <p className="text-sm text-lightGray mb-1">
          Total Players: {playerCountNA !== null && playerCountAS !== null ? playerCountNA + playerCountAS : 'Loading...'}
        </p>
      </div>

      {/* Home Section */}
      <div className="mb-8">
        <h2 className="text-4xl font-extrabold mb-4 text-accentCyan">
          Welcome to Zombie Mini Arena 3.3
        </h2>
        <div>
          <p className="text-lg text-lightGray">
            ZMA is an international community based around the web browser game, Starve.io!
          </p>
          <p className="text-lg text-lightGray">
            We are proud to be Starve.io's biggest, most popular, and longest-lasting private server!
          </p>
        </div>

        {/* <div className="grid gap-0 grid-cols-3 mb-8">
          <img
          src="https://cdn.discordapp.com/emojis/804495543460036639.webp?size=96&quality=lossless"
          alt="Zombie Mini Arena Logo"
          className="mx-auto mb-6 w-32 h-32 object-contain"
          />
          <img
          src="https://cdn.discordapp.com/emojis/561320982313304064.webp?size=240&quality=lossless"
          alt="Zombie Mini Arena Logo"
          className="mx-auto mb-6 w-32 h-32 object-contain"
          />
          <img
          src="https://cdn.discordapp.com/emojis/751984764403187805.webp?size=240&quality=lossless"
          alt="Zombie Mini Arena Logo"
          className="mx-auto mb-6 w-32 h-32 object-contain"
          />
        </div> */}



      </div>

      {/* Key Features Section */}
      <div className="grid gap-8 grid-cols-1 md:grid-cols-2 mb-8">
        {/* Play Zombie Mini Arena Section */}
        <div className="bg-darker p-6 rounded shadow-lg">
          <img
            src="https://cdn.discordapp.com/emojis/1282147754507767950.webp?size=96&quality=lossless"
            alt="Play Zombie Mini Arena"
            className="mx-auto mb-4 w-20 h-20 object-contain"
          />
          <h3 className="text-xl font-bold text-accentCyan mb-2">Play Zombie Mini Arena</h3>
          <p className="text-sm text-lightGray mb-4">
            Collaborate, grow, and fight with players from all around the world!
          </p>
          <div className="flex justify-center space-x-2">
            <a
              href="https://starve.io/?server=community204&region=atlanta"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-accentCyan text-black px-4 py-2 rounded shadow hover:bg-accentBlue transition"
            >
              Play North America
            </a>
            <a
              href="https://starve.io/?server=community198&region=osaka"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-accentCyan text-black px-4 py-2 rounded shadow hover:bg-accentBlue transition"
            >
              Play Asia
            </a>
          </div>
        </div>

        {/* Join Our Discord Community Section */}
        <div className="bg-darker p-6 rounded shadow-lg">
          <img
            src="https://cdn.discordapp.com/emojis/1282146220386680894.webp?size=96&quality=lossless"
            alt="Join Our Discord Community"
            className="mx-auto mb-4 w-20 h-20 object-contain"
          />
          <h3 className="text-xl font-bold text-accentCyan mb-2">Join Our Discord Community</h3>
          <p className="text-sm text-lightGray mb-4">
            Connect with other players, join exclusive events and giveaways, and take your game to the next level!
          </p>
          <Link
            to="https://discord.gg/zma"
            className="bg-accentCyan text-black px-4 py-2 rounded shadow hover:bg-accentBlue transition"
          >
            Join ZMA Discord
          </Link>
        </div>

        {/* View Economy Info Section */}
        <div className="bg-darker p-6 rounded shadow-lg">
          <img
            src="https://cdn.discordapp.com/emojis/1271627348403294228.webp?size=64&quality=lossless"
            alt="View Economy Info"
            className="mx-auto mb-4 w-20 h-20 object-contain"
          />
          <h3 className="text-xl font-bold text-accentCyan mb-2">View Economy Info</h3>
          <p className="text-sm text-lightGray mb-4">
            View all available gear, utilities, collectables, and more!
          </p>
          <Link
            to="/items"
            className="bg-accentCyan text-black px-4 py-2 rounded shadow hover:bg-accentBlue transition"
          >
            Explore Items
          </Link>
        </div>

        {/* Explore Products Section */}
        <div className="bg-darker p-6 rounded shadow-lg">
          <img
            src="https://cdn.discordapp.com/emojis/1246238861689098341.webp?size=96&quality=lossless"
            alt="Explore Products"
            className="mx-auto mb-4 w-20 h-20 object-contain"
          />
          <h3 className="text-xl font-bold text-accentCyan mb-2">Explore Products</h3>
          <p className="text-sm text-lightGray mb-4">
            Browse ZMA subscriptions, WabbitPass, WabbitCash, and in-game gear for sale!
          </p>
          <Link
            to="/products"
            className="bg-accentCyan text-black px-4 py-2 rounded shadow hover:bg-accentBlue transition"
          >
            Learn More
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Home;